import axios, { AxiosRequestHeaders, RawAxiosRequestHeaders } from 'axios';
import { PIM_API_KEY_MAP } from 'data/general-configs';
import getApplicationStage from 'utils/getApplicationStage';
import getApplicationApiStage from 'utils/getApplicationApiStage';
import { handleRefreshTokenV2 } from '@teamfabric/copilot-utilities';
import { v4 as uuidv4 } from 'uuid';
import { unleash } from 'hooks/useFeatureFlags';
import { FEATURE_FLAGS } from 'hooks/useFeatureFlags/utils';
import { parseUnleashVariant } from 'hooks/useFeatureFlag';
import { getTenantId } from 'utils';

let retry = 0;
const MAX_RETRY = 3;

export const getAccountId = () => {
  return window.localStorage.getItem('isLoggedInWithIdV2')
    ? window.sessionStorage.getItem('accountId')
    : window.sessionStorage.getItem('account');
};

const getV2Headers = () => {
  return {
    'X-Fabric-Request-Id': `copilot-oms-${Date.now()}`,
    'Authorization':
      typeof window !== 'undefined'
        ? sessionStorage.getItem('accessToken')
        : '',
  } as RawAxiosRequestHeaders;
};

const getPimConnectorHeaders = () => {
  return {
    'x-site-context': JSON.stringify({
      channel: 12,
      account: getAccountId(), // logged in user account value
    }),
    'x-api-key': getPimApiKey(),
  } as RawAxiosRequestHeaders;
};

const getPimApiKey = () => {
  const stage = getApplicationStage();
  return (
    process.env.PIM_API_KEY ??
    PIM_API_KEY_MAP[stage] ??
    PIM_API_KEY_MAP['dev02']
  );
};

interface BaseUrlStrategy {
  (stage: string, type: string, urls?: Record<string, string>): string;
}

const servicesUrlsMap = {
  inventory: {
    urlKey: 'OMS_API_V2_INVENTORY_URL',
    id: '/inventory',
  },
  pimconnector: {
    urlKey: 'OMS_API_V2_PIMCONNECTOR_URL',
    id: '',
  },
  configurations: {
    urlKey: 'OMS_API_V2_CONFIGURATIONS_URL',
    id: '/configurations',
  },
  default: {
    urlKey: 'OMS_API_V2_DEFAULT_BASE_URL',
  },
};

const ServicesUrls = {
  inventory: process.env.OMS_API_V2_INVENTORY_URL,
  pimconnector: process.env.OMS_API_V2_PIMCONNECTOR_URL,
  configurations: process.env.OMS_API_V2_CONFIGURATIONS_URL,
};

const normalizeURL = URL => (URL.at(-1) === '/' ? URL.slice(0, -1) : URL);

const getAPIBaseURLFromEnvironment: BaseUrlStrategy = (
  stage: string,
  type: string,
  urls: Record<string, string>
) => {
  const specificURL = urls ? urls[type] : ServicesUrls[type];
  if (specificURL) {
    return normalizeURL(specificURL);
  }

  if (!process.env.OMS_API_V2_DEFAULT_BASE_URL) return null;

  const baseURL = process.env.OMS_API_V2_DEFAULT_BASE_URL;
  return `${normalizeURL(baseURL)}/${type}`;
};

// Axios Instance Version:V2
const getAxiosInstanceV2 = (
  type: string,
  getHeaders: () => RawAxiosRequestHeaders = getV2Headers
) => {
  const stage = getApplicationApiStage();

  const client = axios.create({
    baseURL: getAPIBaseURLFromEnvironment(stage, type),
    responseType: 'json',
    headers: getHeaders(),
  });
  client.interceptors.request.use(request => {
    const token = sessionStorage.getItem('accessToken');
    const tenantId = getTenantId({ axios });

    request.headers = {
      ...request.headers,
      ...getHeaders(),
      'Authorization': token,
      ...(request.headers['X-Fabric-Request-Id']
        ? {
            'X-Fabric-Request-Id': `${
              request.headers['X-Fabric-Request-Id']
            }-${uuidv4()}`,
          }
        : {}),
      'x-site-context': JSON.stringify({
        channel: 12,
        account: tenantId,
        date: new Date().toISOString(),
        stage: getApplicationStage(),
        site: 'local',
      }),
    } as unknown as AxiosRequestHeaders;
    return request;
  });
  return client;
};

const clientV2 = {
  inventory: getAxiosInstanceV2('inventory'),
  pimconnector: getAxiosInstanceV2('pimconnector', getPimConnectorHeaders),
  configurations: getAxiosInstanceV2('configurations'),
};

export const handleErrorResponse = async (error, domain) => {
  if (retry >= MAX_RETRY) {
    retry = 0;
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 || error?.response?.status === 403) {
    try {
      retry = retry + 1;
      const data = await handleRefreshTokenV2();

      if (data === 'success') {
        const accessToken = sessionStorage.getItem('accessToken');
        const tenantId = getTenantId({ axios });
        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            'Authorization': accessToken,
            'X-Fabric-Request-Id': `${
              error.config.headers['X-Fabric-Request-Id']
            }-${uuidv4()}`,
            'x-site-context': JSON.stringify({
              channel: 12,
              account: tenantId,
              date: new Date().toISOString(),
              stage: getApplicationStage(),
              site: 'local',
            }),
          },
        };
        return clientV2[domain].request(request);
      } else {
        return Promise.reject(error);
      }
    } catch (error) {}
  }
  if (error && error.response && error.response.status === 500) {
    return error.response;
  }
  return Promise.reject(error);
};

Object.keys(clientV2).forEach(domain => {
  clientV2[domain].interceptors.response.use(
    response => {
      return response;
    },
    error => {
      return handleErrorResponse(error, domain);
    }
  );
});

const updateBaseUrlsFromFlags = (flags: any) => {
  Object.keys(clientV2).forEach(domain => {
    const url =
      flags?.[servicesUrlsMap[domain].urlKey] ||
      (flags?.[servicesUrlsMap.default.urlKey] &&
        flags?.[servicesUrlsMap.default.urlKey] + servicesUrlsMap[domain].id);

    clientV2[domain].defaults.baseURL =
      url ?? clientV2[domain].defaults.baseURL;
  });
};

export const updateV2Client = () => {
  const variant = unleash.getVariant(FEATURE_FLAGS.MT_SERVICE);
  const urls = parseUnleashVariant({ variant });
  const tenantId = getTenantId({});

  const tenantUrls = {
    ...urls?.value?.['default'],
    ...(urls?.value?.[tenantId] || {}),
  };
  updateBaseUrlsFromFlags(tenantUrls); // Update base URLs based on flags
};

export default clientV2;

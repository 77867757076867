import { ChatBubble, GenericError } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import React from 'react';
import ChatBubbleLoadingIcon from '../../../chat-bubble/loading';
import ChatStreaming from './chat-streaming';
import { CHAT_ERROR_MESSAGE, CHAT_MESSAGE_TYPE } from './constants';
import FollowUpSection from './follow-up-section';
import { useChatContainer } from './hook';
import {
  ChatBubbleContainer,
  StyledChatContent,
  StyledErrorContainer
} from './style';

type ChatContainerProps = {
  setShowDefaultView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChipQuery?: string;
  isSideChat?: boolean;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setEventTriggered: React.Dispatch<React.SetStateAction<boolean>>;
  input: string;
  eventTriggered: boolean;
  footerHeight: number;
};

const ChatContainer = ({
  setShowDefaultView,
  selectedChipQuery,
  isSideChat,
  setInput,
  input,
  eventTriggered,
  setEventTriggered,
  footerHeight
}: ChatContainerProps) => {
  const {
    messages,
    label,
    loading,
    chatContainerRef,
    handleInputSubmit,
    quickReplies,
    uiActionCTAs,
  } = useChatContainer({
    selectedChipQuery,
    setShowDefaultView,
    setInput,
    input,
    eventTriggered,
    setEventTriggered
  });

  return (
    <>
      {!isEmpty(messages) &&
        <StyledChatContent ref={chatContainerRef} footerHeight={footerHeight}>
          {messages.map((message, idx) => {
            if (message?.type === CHAT_MESSAGE_TYPE?.USER) {
              return (
                <ChatBubbleContainer
                  key={message.id}
                  footerHeight={footerHeight}
                >
                  <ChatBubble
                    content={message.content}
                    sender={CHAT_MESSAGE_TYPE.USER}
                    avatar={{ label }}
                  />
                </ChatBubbleContainer>
              );
            } else if (message?.type === CHAT_MESSAGE_TYPE.AI) {
              let shouldShowIcon;
              if (idx === 0) {
                shouldShowIcon = true;
              } else {
                shouldShowIcon = messages[idx - 1].type !== CHAT_MESSAGE_TYPE.AI;
              }
              return (
                <ChatStreaming
                  shouldShowIcon={shouldShowIcon}
                  isSideChat={isSideChat}
                  key={message.id}
                  content={message.content}
                />
              );
            } else if (message?.type === CHAT_MESSAGE_TYPE.ERROR) {
              return (
                <StyledErrorContainer key={message.id}>
                  <GenericError
                    error={CHAT_ERROR_MESSAGE}
                    dataTestid='chat-error'
                  />
                </StyledErrorContainer>
              );
            }
          })}
          {(!isEmpty(quickReplies) || !isEmpty(uiActionCTAs)) && (
            <FollowUpSection
              quickReplies={quickReplies}
              uiActionCTAs={uiActionCTAs}
              handleFollowUpClick={async ({
                ctaLabel,
                promptText,
                shouldTriggerResponse,
                applySuggestionText = null,
              }) => {
                await handleInputSubmit({
                  ctaLabel,
                  promptText,
                  shouldTriggerResponse,
                  applySuggestionText
                });
              }}
            />
          )}
          {loading && <ChatBubbleLoadingIcon lastMessageType={last(messages ?? []).type} />}
        </StyledChatContent>
      }
    </>
  );
};

export default ChatContainer;

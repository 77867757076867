
import { DataGrid, Modal } from 'ds4-beta';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getStartCasedString } from '../../../../util';
import { FAB_AI_ROUTE } from '../constants';
import {
  StyledAnalyticsTable,
  StyledSummary,
  TableCell
} from './style';
import {
  COLUMNS_TO_SHOW_CARD,
  getColMaxWidth,
  getDataGridSubTitle,
  getHeader,
  MAX_ROWS_LIMIT,
  ROWS_TO_SHOW_CARD
} from './util';

export const AnalyticsTable = ({ data, event }) => {
  const { pathname } = useLocation();
  const isSideChatView = pathname !== FAB_AI_ROUTE;
  const [isExpanded, setIsExpanded] = useState(false);
  const dataset = data?.response?.[0]?.dataset;
  const analysis = data?.response?.[0]?.analysis;

  const { visualization } = dataset ?? {};
  const { overview } = visualization ?? {};
  const { title = null, range = null } = overview ?? {};

  const cols = useMemo(() => {
    if (!dataset?.columns) return null;
    return dataset?.columns.map((col, idx) => ({
      id: col,
      maxWidth: getColMaxWidth(event, idx),
      label: getStartCasedString(col),
      render: (data: string | Array<string>) => {
        return <TableCell>{data}</TableCell>;
      },
    }))
  }, [dataset?.columns]);

  const rows: Array<Record<string, string>> = dataset?.rows ?? [];

  useEffect(() => {
    if (pathname === FAB_AI_ROUTE) {
      setIsExpanded(false);
    }
  }, [pathname]);

  if (!cols) {
    return null;
  }

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const determineCardOrGrid = (cols, rows) => {
    return (
      cols.length > COLUMNS_TO_SHOW_CARD ||
      (cols.length <= COLUMNS_TO_SHOW_CARD && rows > ROWS_TO_SHOW_CARD)
    );
  };

  const renderDataGrid = ({ sideView, modal }) => {
    const showCard = determineCardOrGrid(cols, rows.length);
    return (
      <DataGrid
        key={sideView ? 'card' : 'datagrid'}
        columns={cols}
        data={rows.slice(0, MAX_ROWS_LIMIT)}
        isLoading={false}
        headerProps={{
          heading: title ?? getHeader(event),
          subTitle: getDataGridSubTitle(range, rows.length),
        }}
        {...(sideView &&
          !modal &&
          showCard && {
          iconProps: { icon: 'AIExpand', onClick: handleExpandClick },
        })}
        showGrid={!sideView || modal || !showCard}
      />
    );
  };

  return (
    <StyledAnalyticsTable>
      {analysis?.summary?.length > 0 && (
        <StyledSummary>{analysis?.summary}</StyledSummary>
      )}
      {renderDataGrid({ sideView: isSideChatView, modal: false })}
      {isExpanded && isSideChatView && (
        <Modal
          size='large'
          isVisible={isExpanded}
          mode='ai'
          onClose={handleExpandClick}
          dataTestid='analytics-datagrid-modal'
        >
          {renderDataGrid({ sideView: isSideChatView, modal: true })}
        </Modal>
      )}
    </StyledAnalyticsTable>
  );
};

import { theme } from 'ds4-beta';
import styled, { css, keyframes } from 'styled-components';
import { CHAT_MESSAGE_TYPE, ChatMessageType } from '../window/components/chat-container/constants';

const TRANSLATE_X_SPACING = 4;

export const ChatLoadingContainer = styled.div<{ lastMessageType: ChatMessageType }>`
  display: flex;
  gap: ${theme.space[2]};
  ${({ lastMessageType }) => lastMessageType !== CHAT_MESSAGE_TYPE.USER && css`
    margin-top: ${theme.space[4]};
  `}
`;

export const ChatBubbleLoadingContainer = styled.div`
  height: 26px;
  position: relative;
  display: inline-block;
  animation: rotateSpinner 500ms linear infinite;

  @keyframes rotateSpinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

export const PrimaryText = styled.div`
  ${theme.typography.body1.medium};
  color: ${theme.color.grey[1000]};
`;

export const SecondaryText = styled.span<{ animationType: ReturnType<typeof keyframes> | null; }>`
  ${({ animationType }) =>
    animationType &&
    css`
      animation: ${animationType} 600ms ease-in-out;
      transform: translateX(${TRANSLATE_X_SPACING}px);
    `}
  ${theme.typography.body1.regular};
  color: ${theme.color.grey[500]};
`;

export const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(${TRANSLATE_X_SPACING}px);
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(${TRANSLATE_X_SPACING}px);
  }
`;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getV3APIHeaders = exports.maxCalendarDate = exports.getMinStartTime = exports.combineDateAndTime = void 0;
var combineDateAndTime = function (date, time) {
    var combinedDate = new Date(date);
    combinedDate.setHours(time.getHours());
    combinedDate.setMinutes(time.getMinutes());
    combinedDate.setSeconds(time.getSeconds());
    return combinedDate;
};
exports.combineDateAndTime = combineDateAndTime;
var getMinStartTime = function () {
    var date = new Date();
    var minutes = date.getMinutes();
    var newMinutes = Math.ceil(minutes / 15) * 15;
    date.setMinutes(newMinutes);
    return date;
};
exports.getMinStartTime = getMinStartTime;
exports.maxCalendarDate = Date.UTC(2050, 11, 31);
var getV3APIHeaders = function () {
    var accessToken = sessionStorage.getItem('accessToken');
    var tenantId = sessionStorage.getItem('accountId');
    return {
        'x-fabric-tenant-id': tenantId,
        'x-fabric-channel-id': 12,
        'x-client-id': 'copilot',
        Authorization: "Bearer ".concat(accessToken),
        'x-fabric-source': 'copilot',
    };
};
exports.getV3APIHeaders = getV3APIHeaders;

import { FabAIController, FabAIObservables } from '@copilot/mfa-communication';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { gracefulJSONParse } from '../../../util';
import {
  ANIMATION_TRANSITION_TIME_MS,
  CHAT_MESSAGE_TYPE,
  CHAT_STREAMING_PAYLOAD,
  ChatMessageType,
  FABAI_CONVERSATION_ID,
  SUPPORTED_EVENT_TYPES,
  SUPPORTED_EVENTS,
} from './constants';
import {
  ACTION_TYPE_OPTIONS,
  HandleFollowUpClickProps,
  QuickReplyType,
  UIActionType,
} from './follow-up-section/types';
import JsonRenderer from './json-renderer';
import { MarkdownRenderer } from './MarkdownRenderer';
import { MessageContent } from './types';
import { getConversationId, updateMessages } from './util';

const sanitizeMarkdown = (text: string): string => {
  return text.replace(/\[NEWLINE\]+/g, `\n`);
};

export const isValidStreamData = (streamData: string) => {
  const lines = streamData.split('\n\n');
  let isValid = true;
  lines.forEach(line => {
    if (line.trim()) {
      try {
        const jsonString = line.replace(/^data:\s*/, '');
        JSON.parse(jsonString);
      } catch (e) {
        isValid = false;
      }
    }
  });
  return isValid;
};

export const useChatStreaming = ({ content }) => {
  const [messages, setMessages] = useState([]);

  const renderMessages = useMemo(() => {
    const renderedMessages = [];
    let currentText = '';
    messages.forEach((msg, index) => {
      if (msg.eventType === SUPPORTED_EVENT_TYPES.TEXT) {
        currentText += msg.data;
      }

      if (
        msg.eventType === SUPPORTED_EVENT_TYPES.JSON ||
        index === messages.length - 1
      ) {
        if (currentText) {
          renderedMessages.push(
            <MarkdownRenderer>{sanitizeMarkdown(currentText)}</MarkdownRenderer>
          );
          currentText = '';
        }

        if (msg.eventType === SUPPORTED_EVENT_TYPES.JSON) {
          renderedMessages.push(
            <JsonRenderer
              key={msg.id}
              content={msg}
              className='fade-in'
              data-testid={`json-response-${index}`}
            />
          );
        }
      }
    });
    return renderedMessages;
  }, [messages]);

  useEffect(() => {
    setMessages(content);
  }, [content]);

  return { renderMessages };
};

export const useChatContainer = ({
  setShowDefaultView,
  selectedChipQuery,
  input,
  setInput,
  eventTriggered,
  setEventTriggered,
}) => {
  const [messages, setMessages] = useState([]);
  const [quickReplies, setQuickReplies] = useState<QuickReplyType[]>([]);
  const [uiActionCTAs, setUIActionCTAs] = useState<UIActionType[]>([]);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const label = useMemo(
    () =>
      ['firstName', 'lastName']
        .map(key => sessionStorage.getItem(key) || '')
        .join(' '),
    []
  );

  useEffect(() => {
    FabAIObservables.RxJsService.receiveDataToChatbot().subscribe({
      next: async dataFromChat => {
        await makeAcknowledgementAPICall(dataFromChat);
      },
    });
  }, []);

  const scrollToBottom = debounce(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    chatContainer.scrollTo({
      top: chatContainer.scrollHeight,
      behavior: 'smooth',
    });

  }, 100);

  useEffect(() => {
    if (messages.length > 0) {
      setShowDefaultView(false);
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    let timeoutId;
    const subscription = FabAIObservables.chatExpansionState$.subscribe(() => {
      // Ensure that whenever the chatExpansion state changes, the user is scrolled to the bottom
      timeoutId = setTimeout(() => {
        scrollToBottom();
      }, ANIMATION_TRANSITION_TIME_MS * 1.1); // to account for the animation when expanding and shrinking
    });

    return () => {
      subscription?.unsubscribe();
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    if (selectedChipQuery?.length > 0) {
      setInput(selectedChipQuery);
      handleInputSubmit({
        ctaLabel: selectedChipQuery,
        promptText: selectedChipQuery
      });
      setInput('');
    }
  }, [selectedChipQuery]);

  useEffect(() => {
    if (eventTriggered) {
      handleInputSubmit({
        ctaLabel: input,
        promptText: input,
      });
      setInput('');
    }
  }, [eventTriggered]);


  const fetchStream = async (promptText: string, chatStreamingPayload) => {
    setLoading(true);
    try {
      const stream = await FabAIController.getChatStream({
        prompt: promptText,
        userId: sessionStorage.getItem('userId'),
        ...chatStreamingPayload,
      });

      const reader = stream.getReader();
      await updateDataFromStream(reader);
      handleStreamCompletion();
    } catch (err) {
      setLoading(false);
      setMessages(prev => [
        ...prev,
        { id: uniqueId(), type: CHAT_MESSAGE_TYPE.ERROR, content: err.message },
      ]);
    }
  };

  const updateDataFromStream = async (reader) => {
    let streamStarted: boolean = true;
    let quickReplies: QuickReplyType[] = [];
    let uiActionCTAs: UIActionType[] = [];
    let events = [];
    const msgId = uniqueId();
    let streamData = '';

    while (streamStarted) {
      events = [];
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = new TextDecoder().decode(value);
      streamData += chunk;
      if (!isValidStreamData(streamData)) {
        continue;
      }

      const lines = streamData.split('\n\n');

      lines.forEach(line => {
        if (line.trim()) {
          try {
            const jsonString = line.replace(/^data:\s*/, '');
            const event = JSON.parse(jsonString);

            if (
              event.event === SUPPORTED_EVENTS.CTA_SUGGESTIONS ||
              event.event === SUPPORTED_EVENTS.CTA_ACK
            ) {
              const ctaList = gracefulJSONParse(event.data);
              const ctaGroupedByActionType = groupBy(ctaList, 'actionType');
              quickReplies =
                ctaGroupedByActionType[ACTION_TYPE_OPTIONS.QUICK_REPLY];
              uiActionCTAs =
                ctaGroupedByActionType[ACTION_TYPE_OPTIONS.UI_ACTION];
            } else if (event.event === SUPPORTED_EVENTS.END) {
              streamStarted = false;
            } else {
              events.push(event);
            }
          } catch (e) {
            console.error('Error parsing event data:', e);
          }
        }
      });
      updateMessagesState(events, msgId);
    }
    setQuickReplies(quickReplies);
    setUIActionCTAs(uiActionCTAs);
  };
  const makeAcknowledgementAPICall = async dataFromChat => {
    try {
      const userId = window.sessionStorage.getItem('userId');
      const conversationId = getConversationId();
      if (
        FabAIObservables.RxJsService.validateDataToChatbot(dataFromChat) &&
        conversationId &&
        userId
      ) {
        const stream = await FabAIController.sendUserAcknowledgement({
          ...dataFromChat.data,
          conversationId,
          userId,
        });
        const reader = stream.getReader();
        updateDataFromStream(reader);
      }
    } catch (e) {
      console.log('error in acknowledgement API call');
      console.log(e);
    }
  };
  const updateMessagesState = (events, messageId) => {
    // Sort and set messages in state
    events = events.map((ev, index) => ({
      ...ev,
      showFabAiIcon: index === 0,
    }));

    events.sort((a, b) => a.id - b.id);
    setMessages(prev => updateMessages(prev, messageId, events));
  };

  const handleStreamCompletion = () => {
    setLoading(false);
  };

  const handleInputSubmit: HandleFollowUpClickProps = async ({
    ctaLabel,
    promptText,
    shouldTriggerResponse = true,
    applySuggestionText = null
  }) => {
    if (!isEmpty(quickReplies)) {
      setQuickReplies([]);
    }
    if (!isEmpty(uiActionCTAs)) {
      setUIActionCTAs([]);
    }
    if (ctaLabel?.length > 0 && !loading) {
      const messagesToAppend: {
        id: string;
        type: ChatMessageType;
        content: string | MessageContent[];
      }[] = [{ id: uniqueId(), type: CHAT_MESSAGE_TYPE.USER, content: ctaLabel }];
      if (!isEmpty(applySuggestionText)) {
        messagesToAppend.push({
          id: uniqueId(),
          type: CHAT_MESSAGE_TYPE.AI,
          content: [{
            data: applySuggestionText,
            event: SUPPORTED_EVENTS.SUMMARY,
            eventType: SUPPORTED_EVENT_TYPES.TEXT,
            id: uniqueId(),
            showFabAiIcon: false,
          }],
        })
      }
      setMessages(prev => [
        ...prev,
        ...messagesToAppend
      ]);
      if (shouldTriggerResponse) {
        window.sessionStorage.setItem(
          FABAI_CONVERSATION_ID,
          CHAT_STREAMING_PAYLOAD.conversationId
        );
        await fetchStream(promptText, CHAT_STREAMING_PAYLOAD);
      }
    }
    setEventTriggered(false);
  };
  return {
    messages,
    label,
    loading,
    chatContainerRef,
    handleInputSubmit,
    quickReplies,
    uiActionCTAs,
  };
};

import {
  AccessToken,
  OktaAuth,
  OktaAuthOptions,
  RefreshToken,
} from '@okta/okta-auth-js';
import { UnleashClient } from 'unleash-proxy-client';
import { navigateToUrl } from 'single-spa';
import { ParsedUserData, Account, AnalyticsUser } from '../@types';
import {
  AAMLController,
  AnalyticsController,
} from '@copilot/mfa-communication';
import api from './api';
import {
  UNLEASH_FEATURE_FLAGS,
  SESSION_STORAGE_KEYS,
} from 'src/utils/constants';
import SelfOnboardingDataEvent, {
  getHomePageRoute,
  redirectAndRemoveLink,
  validateAndSetForceRedirect,
} from '../utils/helper';
import handleAIChatView from 'src/utils/ai-chat-handler';
import handleRootAppContainer from '../utils/root-app-container-handler';
import handleBreadcrumbs from '../utils/breadcrumb-handler';

export const USER_PERMISSIONS_KEY = 'userperm';
export const USER_LABEL_PERMISSIONS_KEY = 'userlabels';

const oktaConfig: OktaAuthOptions = {
  issuer: process.env.OKTA_ISSUER,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth/v2/login`,
  pkce: true,
  responseType: ['token', 'id_token', 'code'],
  scopes: ['openid', 'email', 'profile', 'address', 'phone', 'offline_access'],
};

const config = {
  url: process.env.UNLEASH_PROXY_URL ?? '',
  clientKey: process.env.UNLEASH_CLIENT_KEY ?? '',
  appName: 'copilot-authentication',
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem('accountId') ?? '',
  },
};
export const client = new UnleashClient(config);

export async function fetchAndSetSisenseJWT(accessToken) {
  if (accessToken) {
    const sisenseJWT = await AAMLController?.fetchJWT(accessToken);
    sessionStorage.setItem('sisenseJWT', JSON.stringify(sisenseJWT));
  }
}

export async function fetchAnalyticsAuthAndCharts(accessToken) {
  const isAnalyticsEnabled = client.isEnabled(
    UNLEASH_FEATURE_FLAGS.FEATURE_ANALYTICS_DASHBOARD
  );
  if (isAnalyticsEnabled && accessToken) {
    const data = await AnalyticsController?.getAuthAndCharts(accessToken);
    if (data?.token) {
      sessionStorage.setItem('reportingJWT', data.token);
    }
  }
}

export const renewToken = async (authClient, key: string) => {
  try {
    await authClient.tokenManager.renew(key);
  } catch (e) {
    console.error('Failed to renew expired token', e);
    navigateToUrl('/auth/v2/login');
  }
};

export const setSessionId = async authClient => {
  try {
    const res = await authClient.session.get();
    sessionStorage.setItem('sessionId', res.id);
  } catch (e) {
    console.error(e);
  }
};

export default async function sessionLifecycle(datadogRum) {
  if (!oktaConfig.issuer || !oktaConfig.clientId) {
    return;
  }

  // handle breadcrumb logic
  handleBreadcrumbs();

  const authClient = new OktaAuth(oktaConfig);

  // Triggered when a token has expired
  authClient.tokenManager.on('expired', async function (key, expiredToken) {
    renewToken(authClient, key);
  });
  // Triggered when a token has been renewed
  authClient.tokenManager.on(
    'renewed',
    function (
      key,
      newToken: AccessToken | RefreshToken,
      oldToken: AccessToken | RefreshToken
    ) {
      if (key === 'accessToken') {
        const accessToken: AccessToken = newToken as AccessToken;
        sessionStorage.setItem('accessToken', accessToken.accessToken);
        fetchAndSetSisenseJWT(accessToken.accessToken);
        fetchAnalyticsAuthAndCharts(accessToken.accessToken);
      }
      if (key === 'refreshToken') {
        const refreshToken: RefreshToken = newToken as RefreshToken;
        if (refreshToken?.refreshToken) {
          sessionStorage.setItem('refreshToken', refreshToken.refreshToken);
        }
      }
    }
  );
  // Triggered when an OAuthError is returned via the API (typically during token renew)
  authClient.tokenManager.on('error', function (err) {
    console.error('TokenManager error:', err);
    navigateToUrl('/auth/v2/login');
  });

  // adding redirect listener here
  validateAndSetForceRedirect();

  window.addEventListener('onV1Login', async (event: CustomEvent) => {
    fetchAndSetSisenseJWT(event.detail.accessToken);
  });

  window.document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
      // checks and updates(if required) accessToken when user returns to the page
      handleIdV2TokenRefresh(authClient);
    }
  });

  SelfOnboardingDataEvent();

  window.addEventListener('onLoginSuccess', async (event: CustomEvent) => {
    authClient.tokenManager.start();
    authClient.tokenManager.setTokens(event.detail.tokens);
    sessionStorage.setItem(
      'accessToken',
      event.detail.tokens.accessToken.accessToken
    );
    if (event?.detail?.tokens?.refreshToken?.refreshToken) {
      sessionStorage.setItem(
        'refreshToken',
        event.detail.tokens.refreshToken.refreshToken
      );
    }
    await fetchUserData();
    fetchAndSetSisenseJWT(event.detail.tokens.accessToken.accessToken);
    fetchAnalyticsAuthAndCharts(event.detail.tokens.accessToken.accessToken);
  });

  await setSessionId(authClient);
  const redirectDisabledRoute = ['/auth/login', '/onboarding/signup'];
  if (window.location.pathname === '/auth/v2/login') {
    datadogRum.setUser({ isStaffUserFederated: true }); // set user as federate in datadog
    checkOktaSession(authClient);
  } else if (
    localStorage.getItem('isLoggedInWithIdV2') &&
    !redirectDisabledRoute.some(value =>
      window.location.pathname.includes(value)
    )
  ) {
    if (!sessionStorage.getItem('userId')) {
      navigateToUrl('/auth/v2/login');
      return;
    }
    datadogRum.setUser({ isStaffUserFederated: true }); // set user as federate in datadog
    authClient.start();
  } else {
    datadogRum.setUser({ isStaffUserFederated: false }); // set user as non federate in datadog
  }

  // Handle AI Chat
  handleAIChatView();

  // Handle visibility of Root App Container
  handleRootAppContainer();

  if (sessionStorage.getItem('accessToken')) {
    // check if there is redirect url available.
    redirectAndRemoveLink();
  } else {
    return;
  }
}

export const handleIdV2TokenRefresh = authClient => {
  authClient.tokenManager
    .get('accessToken')
    .then(function (token) {
      if (token && authClient.tokenManager.hasExpired(token)) {
        // Token has been expired due to timeout
        renewToken(authClient, 'accessToken');
      }
    })
    .catch(function (err) {
      // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
      console.error(err);
    });
};

export const checkOktaSession = async authClient => {
  const sessionExists = await authClient?.session?.exists();
  const fromLogin = new URLSearchParams(window.location.search).has(
    'fromLogin'
  );
  if (sessionExists) {
    window.dispatchEvent(
      new CustomEvent('sessionLoading', {
        detail: true,
      })
    );
    await authClient.tokenManager.start();
    let tokensResponse;
    try {
      tokensResponse = await authClient.token.getWithoutPrompt();
    } catch (error) {
      console.error('catch getWithoutPrompt', error);
      window.dispatchEvent(
        new CustomEvent('onLoginFailure', { detail: error.message })
      );
      return;
    }

    authClient.tokenManager.setTokens(tokensResponse.tokens);
    sessionStorage.setItem(
      'accessToken',
      tokensResponse.tokens.accessToken.accessToken
    );
    if (tokensResponse?.tokens?.refreshToken?.refreshToken) {
      sessionStorage.setItem(
        'refreshToken',
        tokensResponse.tokens.refreshToken.refreshToken
      );
    }
    await fetchUserData();
    fetchAndSetSisenseJWT(tokensResponse.tokens.accessToken.accessToken);
    fetchAnalyticsAuthAndCharts(tokensResponse.tokens.accessToken.accessToken);
  } else if (fromLogin) {
    console.error('expected session to exist for login redirect');
    window.dispatchEvent(
      new CustomEvent('onLoginFailure', {
        detail:
          'Expected session from login redirect, but it does not exist. Please try logging in again.',
      })
    );
    return;
  }
};

export const routeToHomeActivity = (
  isNewRouteEnabled: boolean,
  isFabAIHomePageEnabled: boolean
) => {
  // check if there is a route to redirect available here, If yes, push to that route and ignore
  const redirectUrl = localStorage.getItem('redirect_uri');
  if (redirectUrl?.length > 0) {
    redirectAndRemoveLink();
  } else if (isNewRouteEnabled) {
    navigateToUrl(getHomePageRoute(isFabAIHomePageEnabled));
  } else {
    navigateToUrl('/admin/activity');
  }
};

export const fetchUserData = async () => {
  let data;
  try {
    const isNewSelfApiEnabled = client.isEnabled(
      UNLEASH_FEATURE_FLAGS.NEW_USERS_SELF_API
    );

    const { data: apiData } = await (!isNewSelfApiEnabled
      ? api.identity.get('/users/self')
      : api.identityV2.get('/users/self?withAccessPolicies=true'));
    data = apiData;
  } catch (err) {
    window.dispatchEvent(
      new CustomEvent('onLoginFailure', { detail: err.message })
    );
    return;
  }
  const accounts = Object.values(data.accounts ?? {});
  const userData = {
    ...data,
    accounts,
  };

  window.sessionStorage.setItem('user', data.id);
  window.sessionStorage.setItem('userId', data.id);
  window.sessionStorage.setItem('firstName', data.firstName);
  window.sessionStorage.setItem('lastName', data.lastName);
  window.sessionStorage.setItem('email', data.email);

  const accountId = determineAccountId(userData);

  const userStateChangeEvent = new CustomEvent('onUserUpdate', {
    detail: {
      firstName: window.sessionStorage.getItem('firstName'),
      lastName: window.sessionStorage.getItem('lastName'),
      email: window.sessionStorage.getItem('email'),
      accountId: accountId,
    },
  });
  window.dispatchEvent(userStateChangeEvent);

  window.sessionStorage.setItem(
    'logoutRedirectUrl',
    data.logoutRedirectUrl ?? 'null'
  );
  localStorage.setItem('isLoggedInWithIdV2', 'true');
  if (!accounts.length) {
    sessionStorage.removeItem('accounts');
    setUserInAnalytics({
      email: data.email,
      userId: data.id,
      accountId: '',
      accountName: '',
    });
    navigateToUrl('/auth/no-account');
    return;
  }
  setUserDataOnSessionStorage(userData);
  let isFabAIHomePageEnabled = false;
  try {
    client.updateContext({
      userId: accountId,
      properties: { domainName: window.location.hostname },
    });
    await client.start();
    const isSelfOnboardingEnabled = client.isEnabled(
      UNLEASH_FEATURE_FLAGS.FEATURE_SELF_ONBOARDING
    );
    const isNewRouteEnabled = client.isEnabled(
      UNLEASH_FEATURE_FLAGS.FEATURE_L1_URL_CHANGES
    );
    const isConsentFormEnabled = client.isEnabled(
      UNLEASH_FEATURE_FLAGS.FEATURE_CONSENT_FORM
    );
    isFabAIHomePageEnabled = client.isEnabled(
      UNLEASH_FEATURE_FLAGS.FAB_AI_HOMEPAGE
    );

    if (isSelfOnboardingEnabled) {
      window.dispatchEvent(
        new CustomEvent('fetchSelfOnboardingData', {
          detail: {
            accountId,
            eventFromHeader: false,
            isNewRouteEnabled,
            isConsentFormEnabled,
            consented: data?.tncConsent?.consented,
          },
        })
      );
    } else {
      if (!isConsentFormEnabled) {
        routeToHomeActivity(isNewRouteEnabled, isFabAIHomePageEnabled);
      }
      if (isConsentFormEnabled) {
        if (data?.tncConsent?.consented) {
          routeToHomeActivity(isNewRouteEnabled, isFabAIHomePageEnabled);
        } else {
          navigateToUrl('/auth/terms');
        }
      }
    }
  } catch (e) {
    if (data?.tncConsent?.consented) {
      navigateToUrl(getHomePageRoute(isFabAIHomePageEnabled));
    } else {
      navigateToUrl('/auth/terms');
    }
  }
};

export const setPermInLocalStorage = (data: Account) => {
  // set the current user permission into the localstorage
  window.localStorage.setItem(
    USER_PERMISSIONS_KEY,
    JSON.stringify(data.permissions || [])
  );
};

export const setUserInAnalytics = (user: AnalyticsUser) => {
  window?.FS?.identify(user.userId, {
    email: user.email,
    isLoggedInWithIdV2: true,
    accountId: user.accountId,
    accountName: user.accountName,
  });
  window?.DD_RUM?.setUser({
    email: user.email,
    isLoggedInWithIdV2: true,
    accountId: user.accountId,
    accountName: user.accountName,
    isStaffUserFederated: true,
    userId: user.userId,
  });
};

export const determineAccountId = (data: ParsedUserData) => {
  const lastSelectedAccountId = window.localStorage.getItem(
    'lastSelectedAccountId'
  );
  return (
    data.accounts.find(account => account.id === lastSelectedAccountId)?.id ||
    data.accounts[0].id
  );
};

export function setUserDataOnSessionStorage(data: ParsedUserData) {
  window.sessionStorage.setItem('accounts', JSON.stringify(data.accounts));
  window.sessionStorage.setItem(
    'isStaffUserFederated',
    data.isStaffUserFederated ?? 'false'
  );
  window.sessionStorage.setItem('pimVersion', 'pim2');
  const lastSelectedAccountId = window.localStorage.getItem(
    'lastSelectedAccountId'
  );
  if (lastSelectedAccountId) {
    const lastSelectedAccount = data.accounts.find(
      account => account.id === lastSelectedAccountId
    );
    if (lastSelectedAccount) {
      window.sessionStorage.setItem('accountId', lastSelectedAccount.id);
      window.sessionStorage.setItem('accountName', lastSelectedAccount.name);
      setPermInLocalStorage(lastSelectedAccount);
      setUserInAnalytics({
        email: data.email,
        accountId: lastSelectedAccount.id,
        accountName: lastSelectedAccount.name,
        userId: data.id,
      });
      return;
    }
  }
  window.sessionStorage.setItem('accountName', data.accounts[0].name);
  window.sessionStorage.setItem('accountId', data.accounts[0].id);
  window.localStorage.setItem('lastSelectedAccountId', data.accounts[0].id);

  setPermInLocalStorage(data.accounts[0]);
  setUserInAnalytics({
    accountName: data.accounts[0].name,
    accountId: data.accounts[0].id,
    email: data.email,
    userId: data.id,
  });
}

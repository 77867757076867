import { theme } from 'ds4-beta';
import styled, { css } from 'styled-components';

const commonWidthConstraintCss = css`
  @media (min-width: 1024px) {
    max-width: 808px;
  }

  @media (min-width: 375px) and (max-width: 1023px) {
    width: 75%;
  }
`;

export const customScrollbar = `
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.3s;

  &:hover {
    scrollbar-color: ${theme.color.grey[200]} transparent;
  }

  &:not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: ${theme.space[1]};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.color.grey[600]};
    border-radius: ${theme.space[1]};
    transition: background 0.3s;
  }
`;

export const ChatBubbleContainer = styled.div<{ footerHeight: number; }>`
  padding: ${theme.space[5]} 0;
`;

export const StyledChatContent = styled.div<{ footerHeight: number }>`
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;

  ${({ footerHeight }) => css`
    & > *:last-child {
      padding-bottom: calc(${footerHeight}px + ${theme.space[4]}); // space[4] for padding
    }
  `}

  ${commonWidthConstraintCss};
`;

export const StyledChatFooter = styled.div<{ isSideChat: boolean; isLoading: boolean }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  z-index: 1;

  ${({ isSideChat }) => isSideChat && css`
    width: 80%;
  `}

  ${({ isLoading }) => isLoading ? css`
  transform: translateX(calc(-50%));
  ` : css`
  transform: translateX(calc(-50% - ${theme.space[4]})); // theme.space[4]px to adjust for padding
  `}

  ${commonWidthConstraintCss};
  min-width: calc(368px - ${theme.space[6]}); // - space[6] to account for padding
`;


export const StyledProductContainer = styled.div`
  max-width: 808px;
  ${theme.typography.body1.regular};
  color: ${theme.color.grey[1000]};

  .chat-response-wrapper {
    position: relative;
    left: -32px;
  }
`;

export const StyledIcon = styled.div`
  align-self: flex-start;
`;

export const StyledHeading = styled.span`
  ${theme.typography.body1.regular};
  display: inline-block;
  gap: ${theme.space[2]};
  align-items: center;
  margin-bottom: ${theme.space[4]};
`;

export const StyledBoldText = styled.span`
  ${theme.typography.body1.medium};
`;

export const StyledChatStreamContainer = styled.div`
  display: flex;
  gap: ${theme.space[2]};
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  ${customScrollbar};
  ${theme.typography.body1.medium};
`;

export const StyledErrorContainer = styled.div`
`;

export const AnimatedWord = styled.span`
  margin-right: 0.2rem;
  ${theme.typography.body1.regular};
`;

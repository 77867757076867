import { AIChatPrompter, Skeleton } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import ChatContainer from './components/chat-container/chat-container';
import { StyledChatFooter } from './components/chat-container/style';
import {
  AIChatPrompterLoadingState,
  ChatPageContainer,
  FooterPlaceholderContainer,
  StyledLandingPageContainer
} from './components/home-page/landingPage.style';
import LandingPagePrompts from './components/home-page/landingPagePrompts';
import useFabAIChatHomepage from './hooks/useFabAIChatHomepage';

const FabAIChatHomePage = () => {
  const {
    showDefaultView,
    setShowDefaultView,
    selectedChipQuery,
    setSelectedChipQuery,
    input,
    eventTriggered,
    setEventTriggered,
    setInput,
    footerRef,
    footerHeight,
    isSideChat,
    predefinedChips,
  } = useFabAIChatHomepage();

  return (
    <ChatPageContainer isSideChat={isSideChat}>
      {showDefaultView && (
        <>
          <StyledLandingPageContainer isSideChat={isSideChat}>
            <LandingPagePrompts
              predefinedChips={predefinedChips}
              isSideChat={isSideChat}
              setSelectedChipQuery={setSelectedChipQuery} />
          </StyledLandingPageContainer>
          {!isSideChat && <FooterPlaceholderContainer tabIndex={-1} />} {/* a dummy container for flex grow, should not be reachable via keyboard navigation */}
        </>
      )}
      <ChatContainer
        isSideChat={isSideChat}
        setShowDefaultView={setShowDefaultView}
        selectedChipQuery={selectedChipQuery}
        setInput={setInput}
        input={input}
        eventTriggered={eventTriggered}
        setEventTriggered={setEventTriggered}
        footerHeight={footerHeight}
      />
      <StyledChatFooter
        isSideChat={isSideChat}
        ref={footerRef}
        isLoading={isEmpty(predefinedChips)}>
        {isEmpty(predefinedChips) ?
          <AIChatPrompterLoadingState>
            <Skeleton width='100%' height='51px' />
            <Skeleton width={isSideChat ? '300px' : '422px'} height='27px' />
          </AIChatPrompterLoadingState>
          :
          <AIChatPrompter
            placeholder='Ask me anything.'
            onChange={e => {
              setInput(e.target.value);
            }}
            onSubmit={() => {
              setEventTriggered(true);
            }}
            promptValue={input || ''}
            subTitle='Everyone makes mistakes, even AI.  Always triple check your work and verify generated responses.'
          />
        }
      </StyledChatFooter>
    </ChatPageContainer>
  );
};
export default FabAIChatHomePage;

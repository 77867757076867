import isEmpty from 'lodash/isEmpty';
import { SUPPORTED_EVENTS } from '../constants';

export const getHeader = (event: string) => {
  const { PIM_ANALYTICS, OMS_ANALYTICS } = SUPPORTED_EVENTS;
  switch (event) {
    case OMS_ANALYTICS:
      return 'Orders';
    case PIM_ANALYTICS:
      return 'Products';
    default:
      return '';
  }
};

/**
 * Retrieves the maximum column width for a given event, column, and index.
 * 
 * @param {string} event - The event type to determine column width (e.g., 'pim-analytics').
 * @param {number} idx - The column index used to decide width.
 * @returns {number | undefined} The maximum width of the column in pixels, or undefined if no specific width is defined.
 */
export const getColMaxWidth = (event: string, idx: number): (number | undefined) => {
  const { PIM_ANALYTICS } = SUPPORTED_EVENTS;
  if (event === PIM_ANALYTICS) {
    if (idx === 0) {
      // SKU column, make it a small width
      return 100;
    }
    if (idx === 1) {
      // Product name column, make it a little bigger width
      return 175;
    }
  }
  return undefined;
};

export const DOMAIN_APP_DEFAULT_ROUTEMAP = {
  product: '/products/items',
  orders: '/orders',
  inventory: '/inventory/manage-inventory',
};

export const MAX_ROWS_LIMIT = 50;
export const MAX_ROWS_LIMIT_EXCEEDS_TEXT =
  `The inquiry yielded over 50 rows of data, with the first ${MAX_ROWS_LIMIT} displayed here; please specify any filtering or viewing adjustments needed for easier analysis.`;
export const COLUMNS_TO_SHOW_CARD = 2;
export const ROWS_TO_SHOW_CARD = 5;

export const MAX_TREND_LINE_LABEL_SIZE = 20;
export const MAX_AXIS_LABEL_SIZE = 15;
export const TREND_TYPES = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
};

export const getDataGridSubTitle = (range: string | null, rowsLength: number) => {
  if (isEmpty(range)) {
    if (rowsLength < MAX_ROWS_LIMIT) {
      return `Showing ${rowsLength} rows`;
    }
    return MAX_ROWS_LIMIT_EXCEEDS_TEXT;
  }

  return `Last ${range}`;
}
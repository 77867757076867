import { BarChart } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { StyledBarChartContainer } from './style';
import { ChartData, Data, LineColor, TrendStatusTypes, TrendTypes } from './type';
import { MAX_AXIS_LABEL_SIZE, MAX_TREND_LINE_LABEL_SIZE, TREND_TYPES } from './util';

const transformBarChartData = (data: Data): ChartData => {
  const { visualization, labels, rows } = data?.response?.[0]?.dataset || {};
  const { chart, chartType, xAxis, yAxis, trendLines, overview } =
    visualization || {};

  if (
    !chart ||
    chartType !== 'bar' ||
    isEmpty(labels) ||
    xAxis == null ||
    yAxis == null
  ) {
    return null;
  }

  const label = labels[yAxis];
  const barchartData = rows.map(row => ({
    name: row[xAxis],
    [yAxis]: row[yAxis],
  }));

  const dataKeys = [{ name: label, key: yAxis }];
  const trendData = trendLines.map(trendline => ({
    segment: [
      {
        x: trendline?.range?.start,
        y: trendline.value,
      },
      {
        x: trendline?.range?.end,
        y: trendline?.value,
      },
    ],
    label: trendline?.label,
    maxLabelSize: MAX_TREND_LINE_LABEL_SIZE,
    lineColor: 'default' as LineColor
  }));
  return {
    data: barchartData,
    dataKeys,
    ...(!isEmpty(trendLines) && { trendData }),
    ...(!isEmpty(overview) && {
      title: overview?.title,
      subTitle: `Last ${overview?.range}`,
      trendSummary: {
        descriptor: overview?.descriptor,
        metricValue: Math.abs(overview?.currentValues?.[yAxis]),
        trend:
          overview?.valueChange?.changeType === TREND_TYPES.INCREASE
            ? TrendTypes.UP
            : TrendTypes.DOWN,
        prefixSymbol:
          overview?.valueChange?.changeType === TREND_TYPES.INCREASE
            ? '+'
            : '-',
        value: Math.abs(overview?.valueChange?.changePercentage),
        trendStatus:
          overview?.valueChange?.businessImpact === 'NEGATIVE'
            ? TrendStatusTypes.BAD
            : TrendStatusTypes.GOOD
      },
    }),
  };
};

export const BarChartView = ({ data }) => {
  const chartData = transformBarChartData(data);

  if (!chartData) return null;

  return (
    <StyledBarChartContainer data-testid='bar-chart'>
      <BarChart
        showTooltip
        xInterval='equidistantPreserveStart'
        yInterval='equidistantPreserveStart'
        {...chartData}
        maxAxisLabelLength={MAX_AXIS_LABEL_SIZE}
      />
    </StyledBarChartContainer>
  );
};

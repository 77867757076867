import { FabAIObservables } from '@copilot/mfa-communication';
import _, { get, isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';

export const MESSAGE_ID_CHATBOX_KEY = 'message-id-chatbox';

const chatRouteMapping = {
  network_create: '/inventory/network/create',
  fulfillment_rule_set_edit: '/orders/fulfillment-rules/edit/:fulfillment_id',
  fulfilment_rule_set_create: '/orders/fulfillment-rules/create',
};

export const publishDataToChatApp = payload => {
  const dataToChatbot = {
    data: payload,
  };

  // Publish data
  FabAIObservables.RxJsService.publishDataToChatbot(dataToChatbot, {
    log: true,
  });
};

export const constructRouteChatAI = ({ routeDetails, actionStep }) => {
  const id = routeDetails?.attributes?.fulfillmentId || '';

  let path = chatRouteMapping[actionStep];

  if (actionStep === 'fulfillment_rule_set_edit') {
    path = path.replace(':fulfillment_id', id);
  }

  return path;
};

export const getParentKeyFromChild = (name: string) => {
  const splits = formatNameDotNotion(name)?.split?.('.');
  const ans = [];
  for (let i = 0; i < splits.length; i++) {
    ans.push([...(ans[Math.max(i - 1, 0)] || []), splits[i]]);
  }

  return ans?.map(e => e?.join('.'));
};

export const omitDiffsData = obj => {
  _.forIn(obj, function (value, key) {
    if (key === '-diffs' || key.includes('-diffs') || key === 'diffs') {
      delete obj[key];
    } else if (_.isObject(value)) {
      omitDiffsData(value);
    }
  });
};

export const getKeyExist = obj => {
  let exist = false;
  _.forIn(obj, function (value, key) {
    if (key === '-diffs' || key.includes('-diffs') || key === 'diffs') {
      exist = true;
    } else if (_.isObject(value)) {
      if (getKeyExist(value)) {
        exist = true;
      }
    }
  });

  return exist;
};

export const showSuggestValue = names => {
  try {
    const form = useFormContext();
    const v = form.getValues();

    // for create
    return names?.some(
      name =>
        v?.['-diffs'] ||
        get(v, `${name}.-diffs`) ||
        get(v, `${name}-diffs`) === true
    );
  } catch (e) {
    return false;
  }
};

export const formatNameDotNotion = (name: string) => {
  return name.replace(/\[([^\]]+)\]/g, '.$1');
};

export const fulfillmentCreationPayloadForChatAI = (newNetwork, data) => {
  const requiredAIFields = data?.fullData?.requiredAIFields;
  const entityType = data?.fullData?.entityType;
  const suggestionEventId = data?.suggestionEventId;

  return {
    actionStep: 'fulfilment_rule_set_create',
    status: 'SAVED',
    suggestionEventId,
    entities: [
      {
        entityType: entityType,
        requiredAIFields: requiredAIFields?.map(({ path, alias }) => {
          return {
            path,
            alias,
            value: newNetwork[path],
          };
        }),
      },
    ],
  };
};

export const unflattenObject = data => {
  let result = {};
  for (let i in data) {
    let keys = i.split('.');
    keys.reduce((acc, value, index) => {
      return (
        acc[value] ||
        (acc[value] = isNaN(Number(keys[index + 1]))
          ? keys.length - 1 === index
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

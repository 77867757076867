import {
  AIChatPrompterButton,
  Icon,
  Skeleton,
  SkeletonIcon,
} from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { ChipsIconsMapping } from './constants';
import {
  InitialPromptsContainer,
  PredefinedChipContainer,
  StyledHeaderContainer,
  StyledIconContainerRow,
  StyledIconLandingPage,
  StyledSubTitleContainer,
  StyledTitleContainer,
} from './landingPage.style';

const LandingPagePrompts = ({ predefinedChips, setSelectedChipQuery, isSideChat }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chipContainerProps, setChipContainerProps] = useState({
    sm: 12,
    md: isSideChat ? 12 : 6,
    lg: isSideChat ? 12 : 6,
  });

  useEffect(() => {
    setIsLoading(isEmpty(predefinedChips));
  }, [predefinedChips]);

  useEffect(() => {
    setChipContainerProps({
      sm: 12,
      md: isSideChat ? 12 : 6,
      lg: isSideChat ? 12 : 6,
    })
  }, [isSideChat]);

  const handleChatPrompterClick = predefinedChip => {
    setSelectedChipQuery(predefinedChip?.title);
  };

  return (
    <StyledIconLandingPage>
      <StyledHeaderContainer>
        <StyledIconContainerRow isSideChat={isSideChat}>
          {isLoading ?
            <SkeletonIcon size={32} /> :
            <Icon iconName='Fabric' size={24} />
          }
        </StyledIconContainerRow>
        <StyledTitleContainer isSideChat={isSideChat}>
          {isLoading ?
            <Skeleton width={isSideChat ? '100%' : '400px'} height='31px' /> :
            <>Hello. Welcome to fabric!</>
          }
        </StyledTitleContainer>
        <StyledSubTitleContainer isSideChat={isSideChat}>
          {isLoading ?
            <Skeleton width={isSideChat ? '100%' : '400px'} height='31px' /> :
            <>Get started with one of the prompts below or ask me something else.</>
          }
        </StyledSubTitleContainer>
      </StyledHeaderContainer>
      <InitialPromptsContainer isSideChat={isSideChat} padding={false}>
        {isLoading ?
          <>
            {Array.from({ length: 4 }).map((_, idx) => (
              <PredefinedChipContainer {...chipContainerProps} isLoading={isLoading}
                key={`predefined-chip-skeleton-${idx}`}>
                <Skeleton width={isSideChat ? '100%' : '400px'} height='72px' />
              </PredefinedChipContainer>
            ))}
          </> :
          <>
            {predefinedChips?.map((predefinedChip, index) => (
              <PredefinedChipContainer
                {...chipContainerProps}
                key={`predefined-chip-${predefinedChip.title}`}>
                <AIChatPrompterButton
                  dataTestId={`predefined-chip-${index}`}
                  iconName={ChipsIconsMapping[predefinedChip.type]}
                  title={predefinedChip.title}
                  onClick={() => handleChatPrompterClick(predefinedChip)}
                />
              </PredefinedChipContainer>
            ))}
          </>
        }
      </InitialPromptsContainer>
    </StyledIconLandingPage>
  );
};
export default LandingPagePrompts;

import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme, CopilotUIProvider } from 'ds4-beta'
import AppRoutes from './AppRoutes'
import { Provider } from 'react-redux'
import store from 'src/ds4/store'
import { BrowserRouter } from 'react-router-dom';
import { FEATURE_FLAGS } from '../config/constants'
import { unleashClient } from '../api/featureFlag'
import { updateOMSDomainBaseUrl } from '../api'

const Offers = () => {
  const isChicosAccount = unleashClient.isEnabled(FEATURE_FLAGS.IS_CHICOS_ACCOUNT);
  if(isChicosAccount) {
     updateOMSDomainBaseUrl();
  }
  return(
    <Provider store={store}>
      <CopilotUIProvider>
        <ThemeProvider theme={{ theme }}>
          <React.Fragment>
            <GlobalStyle />
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </React.Fragment>
        </ThemeProvider>
      </CopilotUIProvider>
    </Provider>)
}

export default Offers

import { AuthController } from "@copilot/mfa-communication";
import { useFlag, useUnleashContext } from "@unleash/proxy-client-react";
import produce from "immer";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Link } from "../@types";
import {
  COPILOT_HEADER_CDP,
  COPILOT_HEADER_L1_LINKS_UPDATE,
  COPILOT_HEADER_MARKETPLACE,
  COPILOT_HEADER_OFFERS,
  COPILOT_HEADER_OMS,
  COPILOT_HEADER_PIM,
  COPILOT_HEADER_RTPE,
  COPILOT_HEADER_TERMINOLOGY_UPDATE,
  COPILOT_HEADER_XM,
  COPILOT_SELF_ONBOARDING,
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
} from "../lib/constants/routes";
import { setupHeaderLinksV2, setupRTPE } from "../lib/utils/util";
import initConfig, { HEADER_LINKS } from "../settings";

interface ConfigContextData {
  config: {
    company: string;
    logo: {
      height: string;
      image: string;
      width: string;
    };
    showNotification: boolean;
    notifications: Array<{
      type: string;
      day: string;
      text: string;
      actionLabel: string;
      clickHandler: () => any;
    }>;
    links: Array<Link>;
    user: {
      userEmail: string;
      userName: string;
      userNav: Array<{
        label: string;
        link: string;
      }>;
    };
  };
  setConfig: React.Dispatch<React.SetStateAction<object>>;
}

const ConfigContext = createContext<ConfigContextData>({} as ConfigContextData);

const ConfigContextProvider = ({ children }) => {
  const [config, setConfig] = useState(initConfig);
  const updateContext = useUnleashContext();

  const rtpe = useFlag(COPILOT_HEADER_RTPE);
  const pim = useFlag(COPILOT_HEADER_PIM);
  const xm = useFlag(COPILOT_HEADER_XM);
  const offers = useFlag(COPILOT_HEADER_OFFERS);
  const oms = useFlag(COPILOT_HEADER_OMS);
  const cdp = useFlag(COPILOT_HEADER_CDP);
  const marketplace = useFlag(COPILOT_HEADER_MARKETPLACE);

  const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT_ID);
  const isNewTerminologyEnabled = useFlag(COPILOT_HEADER_TERMINOLOGY_UPDATE);
  const isNewL1LinksEnabled = useFlag(COPILOT_HEADER_L1_LINKS_UPDATE);
  const selfOnboarding = useFlag(COPILOT_SELF_ONBOARDING);

  const updateUnleashContextWithInfo = (
    accountId = window.sessionStorage.accountId,
  ) => {
    updateContext({
      userId: accountId,
      properties: {
        domainName: window.location.hostname,
      },
    });
  };

  useEffect(() => {
    updateUnleashContextWithInfo();
    const updateListener = (event: CustomEvent) => {
      updateUnleashContextWithInfo(event.detail.accountId);
      setConfig((prev) => {
        return produce(prev, (draft) => {
          draft.user.userName = `${event.detail.firstName} ${event.detail.lastName}`;
          draft.user.userEmail = event.detail.email;
        });
      });
    };
    window.addEventListener("onUserUpdate", updateListener);

    setConfig((prev) => {
      return produce(prev, (draft) => {
        const userData = AuthController.fetchLoggedInUser();
        draft.user.userName = `${userData.name.first} ${userData.name.last}`;
        draft.user.userEmail = userData.email;
      });
    });

    if (!accountId) {
      return;
    }

    return () => {
      window.removeEventListener("onUserUpdate", updateListener);
    };
  }, [accountId]);

  useEffect(() => {
    const orgDetails = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.ORG_DETAIL),
    );
    const tenantDetail = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.TENANT_DETAIL),
    );

    const isIdV2Login = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_ID_V2);

    const shouldFilterForProvisionedProducts = !!(
      isIdV2Login &&
      selfOnboarding &&
      orgDetails
    );
    setupRTPE(rtpe, setConfig);
    const featureFlagConfig = {
      [HEADER_LINKS.MARKETPLACE.productId]: marketplace,
      [HEADER_LINKS.PIM.productId]: pim,
      [HEADER_LINKS.XM.productId]: xm,
      [HEADER_LINKS.OFFERS.productId]: offers,
      [HEADER_LINKS.OMS.productId]: oms,
      [HEADER_LINKS.CDP.productId]: cdp,
    };
    setupHeaderLinksV2(
      setConfig,
      featureFlagConfig,
      isNewTerminologyEnabled,
      isNewL1LinksEnabled,
      shouldFilterForProvisionedProducts,
      tenantDetail?.status ?? "",
      orgDetails?.products ?? [],
      accountId,
    );
  }, [
    accountId,
    rtpe,
    marketplace,
    isNewTerminologyEnabled,
    isNewL1LinksEnabled,
    pim,
    xm,
    offers,
    oms,
    cdp,
    selfOnboarding,
  ]);

  return (
    <ConfigContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

function useConfig() {
  const context = useContext(ConfigContext);
  return context;
}

export { ConfigContextProvider, useConfig };

import { Icon } from 'ds4-beta';
import React, { useEffect, useState } from 'react';
import { ChatMessageType } from '../window/components/chat-container/constants';
import {
  ChatBubbleLoadingContainer,
  ChatLoadingContainer,
  fadeInRight,
  fadeOut,
  PrimaryText,
  SecondaryText,
  TextContainer
} from './styles';

const INITIAL_DELAY = 3500;
const EASE_IN_INTERVAL = 600;
const DISPLAY_INTERVAL = 2000;
const SECONDARY_TEXTS = [
  'Understanding request...',
  'Gathering information...',
  'Reviewing data...',
];

const ChatBubbleLoadingIcon = ({ lastMessageType }: { lastMessageType: ChatMessageType }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(-1);
  const [animationType, setAnimationType] = useState(fadeInRight);

  const startInitialAnimation = () => {
    setCurrentTextIndex(0);
    setAnimationType(fadeInRight);
  };

  const updateTextIndex = () => {
    setCurrentTextIndex((prevIndex) => (prevIndex + 1) % SECONDARY_TEXTS.length);
  };

  const changeTextWithAnimation = () => {
    setAnimationType(fadeOut);
    setTimeout(() => {
      updateTextIndex();
      setAnimationType(fadeInRight);
    }, EASE_IN_INTERVAL);
  };

  useEffect(() => {
    // Start the animation cycle with a delay of 3500ms for the first load
    const firstTimeout = setTimeout(startInitialAnimation, INITIAL_DELAY);

    // Change the text every 2000ms and cycle through the list with the fade-in and fade-out animations
    let interval;
    if (currentTextIndex !== -1) {
      interval = setInterval(changeTextWithAnimation, DISPLAY_INTERVAL + EASE_IN_INTERVAL);
    }

    return () => {
      clearTimeout(firstTimeout);
      clearInterval(interval);
    };
  }, [currentTextIndex]);

  return (
    <ChatLoadingContainer lastMessageType={lastMessageType} data-testid='chat-bubble-loading-icon'>
      <ChatBubbleLoadingContainer>
        <Icon iconName='AISpinner' size={24} />
      </ChatBubbleLoadingContainer>
      <TextContainer>
        <PrimaryText>Working on that.</PrimaryText>
        <SecondaryText animationType={animationType} key={currentTextIndex}>
          {SECONDARY_TEXTS[currentTextIndex]}
        </SecondaryText>
      </TextContainer>
    </ChatLoadingContainer>
  );
};

export default ChatBubbleLoadingIcon;

import { COPILOT_ROUTES } from '@teamfabric/copilot-utilities';
import { v4 as uuidv4 } from 'uuid';

export const CHAT_MESSAGE_TYPE = {
  USER: 'user',
  AI: 'ai',
  ERROR: 'error',
} as const;

export type ChatMessageType = typeof CHAT_MESSAGE_TYPE[keyof typeof CHAT_MESSAGE_TYPE];

export const SUPPORTED_EVENT_TYPES = {
  TEXT: 'text',
  JSON: 'json',
} as const;

export const SUPPORTED_EVENTS = {
  PRODUCT_DESCRIPTION: 'product-description',
  OMS_ANALYTICS: 'oms-analytics',
  PIM_ANALYTICS: 'pim-analytics',
  CTA_SUGGESTIONS: 'ui-cta-suggestions',
  EXCEPTION: 'exception',
  SUMMARY: 'summary',
  CTA_ACK: 'cta-ack',
  END: 'end'
} as const;

export const CHAT_STREAMING_PAYLOAD = {
  conversationId: uuidv4(),
  conversationGroup: 'Home',
};

export const CHAT_ERROR_MESSAGE =
  'It looks like there is a connection problem. Please check your internet connection and try again.';

export const HOME_ACTIVITY_ROUTE = '/home/activity';
export const FAB_AI_ROUTE = COPILOT_ROUTES.fabAI.basePath;

export const FABAI_CONVERSATION_ID = 'fabai-conversation-id';

export const AI_ICON_SIZE = 24;

export const ANIMATION_TRANSITION_TIME_MS = 300;
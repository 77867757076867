import { FabAIObservables } from '@copilot/mfa-communication';
import { isNil } from 'lodash';

export const MESSAGE_ID_CHATBOX_KEY = 'message-id-chatbox';

const chatRouteMapping = {
  network_create: '/inventory/network/create',
  fulfillment_rule_set_edit: '/orders/fulfillment-rules/edit/:fulfillment_id',
  fulfilment_rule_set_create: '/orders/fulfillment-rules/create',
};

export const publishDataToChatApp = payload => {
  const dataToChatbot = {
    data: payload,
  };

  // Publish data
  FabAIObservables.RxJsService.publishDataToChatbot(dataToChatbot, {
    log: true,
  });
};

export const constructRouteChatAI = ({ routeDetails, actionStep }) => {
  const id = routeDetails?.attributes?.fulfillmentId || '';

  let path = chatRouteMapping[actionStep];

  if (actionStep === 'fulfillment_rule_set_edit') {
    path = path.replace(':fulfillment_id', id);
  }

  return path;
};

export const showSuggestValue = (
  diffs,
  name,
  currentValue,
  mode = 'CREATE'
) => {
  // for create
  if (mode === 'CREATE') {
    return (
      diffs?.['all'] ||
      Object.keys(diffs || {})?.some(
        key =>
          // check map exact field (name, description, stock)
          (name?.includes(key) && diffs[key] == true) ||
          // check map for rule (need to check attributeName)
          (key?.startsWith(name) &&
            key?.endsWith('.attributeName.id') &&
            diffs[key] === currentValue)
      )
    );
  }

  const chatboxValue = diffs?.[name];

  // for edit
  return !isNil(chatboxValue) && chatboxValue == currentValue;
};

export const formatNameDotNotion = (name: string) => {
  return name.replace(/\[([^\]]+)\]/g, '.$1');
};

export const networkCreationPayloadForChatAI = (newNetwork, data) => {
  const requiredAIFields = data?.fullData?.requiredAIFields;
  const entityType = data?.fullData?.entityType;
  const suggestionEventId = data?.suggestionEventId;

  return {
    actionStep: 'network_create',
    status: 'SAVED',
    suggestionEventId,
    entities: [
      {
        entityType: entityType,
        requiredAIFields: requiredAIFields?.map(({ path, alias }) => {
          return {
            path,
            alias,
            value: newNetwork[path],
          };
        }),
      },
    ],
  };
};

export const unflattenObject = data => {
  let result = {};
  for (let i in data) {
    let keys = i.split('.');
    keys.reduce((acc, value, index) => {
      return (
        acc[value] ||
        (acc[value] = isNaN(Number(keys[index + 1]))
          ? keys.length - 1 === index
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

import { EnvironmentScope, EnvironmentScopeEnum } from './types';

export const FEATURE_FLAGS = Object.freeze({
  LOCATION_CAPACITY_OVERRIDE: 'LOCATION_CAPACITY_OVERRIDE',
  LOCATION_OUTAGE: 'LOCATION_OUTAGE',
  USE_MULTI_VALUES_LOCATION_ATTRIBUTES: 'USE_MULTI_VALUES_LOCATION_ATTRIBUTES',
  LOCATION_MORE_FILTERS: 'LOCATION_MORE_FILTERS',
  LOCATION_IMPORTS: 'LOCATION_IMPORTS',
  LOCATION_EXPORTS: 'LOCATION_EXPORTS',
  INVENTORY_ACTIVITY_LOGS_TAB: 'INVENTORY_ACTIVITY_LOGS_TAB',
  COPILOT_RBAC_PERMISSIONS: 'COPILOT_RBAC_PERMISSIONS',
  CONFIGURATIONS_V3: 'CONFIGURATIONS_V3',
  SEARCH_PAYLOAD_PARAMS: 'SEARCH_PAYLOAD_PARAMS',
  INVENTORY_STATUS: 'INVENTORY_STATUS',
  PIM_ATTRIBUTES_EXPORT: 'PIM_ATTRIBUTES_EXPORT',
  MT_SERVICE: 'MT_SERVICE',
  ENABLE_FILE_MD5_HASH_COPILOT: 'ENABLE_FILE_MD5_HASH_COPILOT',
  ENABLE_LOCATION_NAME: 'ENABLE_LOCATION_NAME',
  ENABLE_CHAT_AI: 'ENABLE_CHAT_AI',
  USE_BFF: 'USE_BFF',
});

export const mapStageToEnvironmentScope = (stage: string): EnvironmentScope => {
  switch (stage) {
    case 'prod01':
    case 'prod02':
    case 'live': {
      return EnvironmentScopeEnum.production;
    }
    case 'stg02': {
      return EnvironmentScopeEnum.staging;
    }
    case 'uat':
    case 'sandbox': {
      return EnvironmentScopeEnum.sandbox;
    }
    case 'testing':
    case 'dev02': {
      return EnvironmentScopeEnum.development;
    }
    default: {
      return stage;
    }
  }
};

import { FabAIObservables } from '@copilot/mfa-communication';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FEATURE_FLAGS } from 'hooks/useFeatureFlags/utils';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { constructRouteChatAI, MESSAGE_ID_CHATBOX_KEY } from 'utils/fabAI';

const AppChatboxController = () => {
  const navigate = useNavigate();

  const { active: enableChatAI, isFetching: fetchingEnableChatAI } =
    useFeatureFlag(FEATURE_FLAGS.ENABLE_CHAT_AI);

  useEffect(() => {
    if (fetchingEnableChatAI) return;

    if (enableChatAI) {
      // Subscribe to the data from the chatbot
      FabAIObservables.RxJsService.receiveDataFromChatbot().subscribe({
        next: data => {
          console.log(
            'Init chatbox inventory',
            FabAIObservables.RxJsService,
            data
          );
          if (data) {
            const {
              routeDetails,
              entities,
              actionStep,
              messageId,
              bannerText,
            } = data?.data?.[0];

            const { suggestionEventId } = data;

            console.log('Received data from chatbot:', data);

            if (
              isEqual(sessionStorage.getItem(MESSAGE_ID_CHATBOX_KEY), messageId)
            )
              return;

            const path = constructRouteChatAI({ routeDetails, actionStep });

            if (path) {
              sessionStorage.setItem(MESSAGE_ID_CHATBOX_KEY, messageId);
              navigate(path, {
                state: { ...entities, suggestionEventId, bannerText },
              });
            }
          }
        },
      });
    }
  }, [fetchingEnableChatAI, enableChatAI]);

  return null;
};

export default AppChatboxController;
